.home {
  padding: 10px;

  .topic-card {
    border-width: 2px;
  }

  .topic-form {
    padding: 10px 10px 0;
  }

  .remove-topic-button {
    padding: 0 8px 3px;
  }

  .remove-resource-button {
    padding: 0 8px 6px;
  }

  .add-resource-button {
    padding: 0 10px 10px;
  }

  .button-group {
    padding-top: 10px;
  }
}
